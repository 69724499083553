.PharmacyLoader-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
 
.PharmacyLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
}

/* ... rest of the styles ... */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.PharmacyLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  position: relative;
}

.PharmacyLoader-pill {
  width: 80%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(90deg, #34a853 50%, #4285f4 50%);
  position: absolute;
  animation: rotate 2s linear infinite, pulse 1.5s ease-in-out infinite;
}

@media (max-width: 768px) {
  .PharmacyLoader {
    width: 60px;
    height: 30px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ... existing styles ... */

.PharmacyLoader-text {
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 10px;
  animation: fadeIn 2s infinite;
}
