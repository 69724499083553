.dialog-title {
  background-color: #3498db;
  color: white;
  padding: 16px 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.dialog-content {
  padding: 24px;
}

.dialog-button-cancel {
  color: #f44336;
}

.dialog-button-confirm {
  color: #4caf50;
}

.autocomplete-container {
  margin-top: 16px;
}
.confirm-dialog-title {
  color: #34a853;
  font-weight: bold;
}

.confirm-dialog-content {
  color: #4285f4;
}

.confirm-dialog-button-cancel {
  background-color: #4285f4;
  color: #ffffff;
}

.confirm-dialog-button-cancel:hover {
  background-color: #1967d2;
}

.confirm-dialog-button-confirm {
  background-color: #a83434;
  color: #ffffff;
}

.confirm-dialog-button-confirm:hover {
  background-color: #7a0b0b;
}
 .note {
  font-size: 0.9rem;
  font-style: italic;
  color: #999999;
  margin-top: 10px;
}
