.pharmacy-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.pharmacy {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 150px; /* Increased to accommodate the larger logo */
  height: 330px; /* Increased to accommodate the larger logo */
  position: relative;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.pharmacy-apple {
  animation: pulse 2s linear infinite;
  margin-bottom: 30px; /* Increased to accommodate the larger logo */
}

.pharmacy-logo img {
  width: 150px; /* Increased to 1.5 times the original size */
  height: 150px; /* Increased to 1.5 times the original size */
  animation: pulse 2s linear infinite;
}

@media (max-width: 768px) {
  .pharmacy {
    width: 90px; /* Increased to accommodate the larger logo */
    height: 210px; /* Increased to accommodate the larger logo */
  }

  .pharmacy-apple svg, .pharmacy-logo img { 
    width: 45px; /* Increased to 1.5 times the original size */
    height: 45px; /* Increased to 1.5 times the original size */
  }

  .pharmacy-apple {
    margin-bottom: 15px; /* Increased to accommodate the larger logo */
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pharmacy-text {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 15px;
  animation: fadeIn 2s infinite;
}
