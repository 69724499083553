.map-container {
    height: 600px;
    }

    .marker-pin {
        width: 25px;
        height: 25px;
        background-color: black;
        border-radius: 50%;
        /* İşaretçiye pin benzeri bir stil ekleyebilirsiniz, örnek olarak: */
        transform: translateY(-50%) rotate(45deg); /* İğne eğimi ve yukarı çıkma ayarı */
        border: 2px solid white; /* Beyaz bir kenarlık ekleyebilirsiniz */
      }