/* Custom Toastr CSS for Pharmacy Theme */
.toastr {
    border-radius: 5px;
  }
  
  .toast-success {
    background-color:  #3498db !important;/* Green color */
  }
  
  .toast-error {
    background-color: #e74c3c !important; /* Red color */
  }
  
  .toast-info {
    background-color: #3498db !important; /* Blue color */
  }
  
  .toast-warning {
    background-color: #f1c40f !important; /* Yellow color */
  }
  
  .toast-progress {
    background-color: #34495e !important; /* Dark color */
  }
  